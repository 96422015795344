.blog-section {
  padding: 145px 0px;
  @include max-screen(1199) {
    padding: 100px 0px;
  }
  background: #f7faff;
  background-image: url("../images/blog/bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-card {
  margin-top: 30px;
  @include max-screen(991) {
    margin-top: 0px;
  }
  background-color: $white;
  padding: 30px;
  border-radius: 15px;

  &:hover {
    background-image: -moz-linear-gradient(
      0deg,
      rgb(2, 18, 106) 0%,
      rgb(38, 71, 200) 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(2, 18, 106) 0%,
      rgb(38, 71, 200) 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      rgb(2, 18, 106) 0%,
      rgb(38, 71, 200) 100%
    );
  }

  .thumb {
    background-color: #f7faff;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 15px;
    a {
      display: block;
      img {
        transition: all 0.3s;
      }
    }
  }

  &:hover {
    .thumb {
      img {
        transform: scale(1.05);
      }
    }
  }
  .content {
    p {
      color: #747785;
      font-weight: 700;
      line-height: 2.5;
      margin-bottom: 15px;
      font-size: 14px;
      span {
        background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .title {
      font-weight: 700;
      color: $primary;
      line-height: 1.59;
      font-size: 22px;

      @include max-screen(1199) {
        font-size: 18px;
      }

      a {
        color: inherit;
        &:hover {
          color: $warning;
        }
      }
    }
  }

  &:hover {
    .content {
      p {
        color: $white;
      }
      .title {
        background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        a {
          &:hover {
            color: inherit;
          }
        }
      }
    }
  }
}

.blog-meta-cards {
  margin-bottom: -30px;
}

.blog-meta-card {
  background-color: $white;
  display: flex;
  @include max-screen(575) {
    flex-direction: column;
  }
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
  transition: all 0.3s;
  &:hover {
    background-image: -moz-linear-gradient(
      0deg,
      rgb(2, 18, 106) 0%,
      rgb(38, 71, 200) 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(2, 18, 106) 0%,
      rgb(38, 71, 200) 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      rgb(2, 18, 106) 0%,
      rgb(38, 71, 200) 100%
    );

    .content {
      p {
        color: $white;
      }
      .title {
        background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        a {
          color: inherit;
          &:hover {
            background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  .thumb {
    margin-right: 30px;
    @include max-screen(575) {
      margin-bottom: 30px;
    }
    border-radius: 15px;
    overflow: hidden;
  }
  .content {
    flex: 1;
    p {
      color: #747785;
      font-weight: 700;
      line-height: 2.5;
      margin-bottom: 15px;
      font-size: 14px;
      span {
        background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .title {
      font-weight: 700;
      color: $primary;
      line-height: 1.59;
      font-size: 21px;

      @include max-screen(1199) {
        font-size: 18px;
      }
      a {
        color: inherit;
        &:hover {
          color: $warning;
        }
      }
    }
  }
}

.blog-grid-card {
  @include max-screen(767) {
    padding-top: 30px;
  }
  background-color: #f7faff;
  overflow: hidden;
  border-radius: 15px;

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.blog-grid-thumb {
  display: block;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  img {
    border-radius: 15px;
    transition: all 0.3s linear;
  }
}

.blog-grid-content ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.blog-grid-content ul li {
  text-transform: capitalize;
  display: inline-block;
  font-size: 14px;
}

.blog-grid-content {
  padding: 30px 30px;
  max-width: 390px;
  margin: 0 auto;
  .title {
    text-transform: capitalize;
    font-weight: 700;
    color: #02126a;
    &:hover {
      color: $warning;
    }
    line-height: 1.5;
    font-size: 20px;
    @include max-screen(992, 1199) {
      font-size: 18px;
    }

    a {
      color: inherit;
    }
  }
  .btn {
    text-transform: capitalize;
  }
  .link-share {
    font-weight: 400;
    color: #596190;
    &:hover {
      color: $warning;
    }
    a {
      color: inherit;
    }
  }
  .comments {
    font-weight: 400;
    color: #596190;
    &:hover {
      color: $warning;
    }
    a {
      color: inherit;
    }
  }
  .date {
    font-weight: 400;
    color: #596190;

    i {
      margin-right: 5px;
    }
  }
}

.pagination {
  justify-content: center;
  .page-item {
    margin: 0 5px;
    &.active {
      .page-link {
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #fff;
        border-color: transparent;
      }
    }
    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 55px;
      height: 55px;
      color: #fff;
      font-size: 20px;
      border-radius: 50%;

      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#fc7318),
        color-stop(#fe8415),
        color-stop(#fe9515),
        color-stop(#fea51a),
        to(#feb423)
      );
      background: -webkit-linear-gradient(
        left,
        #fc7318,
        #fe8415,
        #fe9515,
        #fea51a,
        #feb423
      );
      background: -o-linear-gradient(
        left,
        #fc7318,
        #fe8415,
        #fe9515,
        #fea51a,
        #feb423
      );
      background: linear-gradient(
        to right,
        #fc7318,
        #fe8415,
        #fe9515,
        #fea51a,
        #feb423
      );
      background-image: linear-gradient(
        to right,
        rgb(252, 115, 24),
        rgb(254, 132, 21),
        rgb(254, 149, 21),
        rgb(254, 165, 26),
        rgb(254, 180, 35)
      );
      background-origin: padding-box;
      background-clip: border-box;
      border: solid 2px transparent;
      background-image: -webkit-linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        -webkit-linear-gradient(349deg, #fc7318 0%, #feb423 100%);
      background-image: -o-linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        -o-linear-gradient(349deg, #fc7318 0%, #feb423 100%);
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #fc7318 0%, #feb423 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      -webkit-box-shadow: 2px 1000px 1px #f7faff inset;
      box-shadow: 2px 1000px 1px #f7faff inset;
      color: #fc7418;
      &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #fff;
        border-color: transparent;
      }
    }
  }
}
