.service-carousel-section {
  position: relative;
  padding-top: 150px;
  @include max-screen(1199) {
    padding-top: 100px;
  }
  margin-bottom: 55px;
}

.service-carousel-content {
  background-color: #f7faff;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  .service-img {
    border-radius: 15px;
  }
}

.service-carousel {
  .swiper-button-prev,
  .swiper-button-next {
    width: 55px;
    height: 55px;
    color: $white;
    font-size: 20px;
    border-radius: 50%;
  }

  .swiper-button-prev {
    left: 50px;
    background: $btn-warning;
    border: solid 2px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #fc7318 0%, #feb423 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #f7faff inset;
    color: $warning;
    &:hover {
      box-shadow: none;
      color: $white;
      border-color: transparent;
    }
  }
  .swiper-button-next {
    right: 50px;
    background: $btn-warning;
    border: solid 2px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #fc7318 0%, #feb423 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #f7faff inset;
    color: $warning;
    &:hover {
      box-shadow: none;
      color: $white;
      border-color: transparent;
    }
  }

  .swiper-button-prev::after,
  .swiper-container-rtl .swiper-button-next::after {
    display: none;
  }
  .swiper-button-next::after,
  .swiper-container-rtl .swiper-button-next::after {
    display: none;
  }
}

// service-details
.service-details-section {
  padding-bottom: 150px;
  @include max-screen(1199) {
    padding-bottom: 100px;
  }
}
.service-details {
  .title {
    color: $primary;
    line-height: 1.857;
    font-size: 35px;

    @include max-screen(768, 1199) {
      font-size: 30px;
    }
    @include max-screen(767) {
      font-size: 25px;
    }
  }
  p {
    margin-bottom: 30px;
    @include max-screen(991) {
      margin-bottom: 20px;
    }
  }
  .experience {
    margin-bottom: 30px;
  }
  img {
    @include max-screen(991) {
      display: block;
      margin: 0 auto 30px;
    }
  }
}

.next-prev-section {
  margin-top: 30px;
  padding: 20px 25px 20px;
  @include min-screen(1200) {
    padding: 20px 25px 0px;
  }
  background-color: rgb(247, 250, 255);
  border-radius: 12px;
}
.service-prev {
  .img {
    margin-right: 30px;
  }
  .conten {
  }
}
.service-next {
  .img {
    margin-left: 30px;
  }
  .conten {
    text-align: right;
  }
}
.service-media {
  display: flex;
  .img {
    background-color: rgb(255, 255, 255);
    padding: 15px 5px;

    @include min-screen(1200) {
      margin-bottom: -15px;
    }
    box-shadow: 0px 22px 35px 0px rgba(2, 18, 106, 0.08);
    border-radius: 12px;
  }
  .conten {
    flex: 1;
    .title {
      font-weight: 700;
      color: $primary;
      line-height: 1.35;
      margin-bottom: 5px;
      font-size: 20px;
      @include max-screen(992, 1199) {
        font-size: 18px;
      }
      @include max-screen(768, 991) {
        font-size: 15px;
      }
      @include max-screen(767) {
        font-size: 16px;
      }
      @include max-screen(575) {
        font-size: 13px;
      }
    }
    .link {
      background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
      background-clip: border-box;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 400;
      line-height: 1.928;
      font-size: 14px;
    }
  }
}

// progress
.progress {
  background-color: #f7faff;
  height: 6px;
  border-radius: 0;
  overflow: visible;
  margin-bottom: 12px;
}

.progress-bar {
  position: relative;
  justify-content: end;
  text-align: right;
  overflow: visible;
  width: 0;
  background-image: -moz-linear-gradient(
    0deg,
    rgb(252, 115, 24) 0%,
    rgb(254, 180, 35) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(252, 115, 24) 0%,
    rgb(254, 180, 35) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(252, 115, 24) 0%,
    rgb(254, 180, 35) 100%
  );
  transition: width 1.5s ease-in-out;

  &::before {
    position: absolute;
    top: -5px;
    right: 0;
    width: 3px;
    height: 15px;
    content: "";
    background-color: rgb(254, 180, 35);
  }
  color: #596190;
  line-height: 1.928;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 14px;
  & span {
    display: block;
    margin-right: -15px;
    position: absolute;
    right:0;
    top: 20px;
    transition: 1s ease-in-out;
    opacity: 0;
    transform: scale(0.5);
    &.opacity {
      opacity: 1;

      transform: scale(1);
    }
  }
}

.ht-progress {
  .ht-progress-item {
    margin-bottom: 20px;
    .title {
      color: #596190;
      line-height: 1.928;
      text-transform: capitalize;
      margin-bottom: 0;
      font-size: 14px;
    }
  }
}
