.case-details-carousel {
  .swiper-slide {
    &.swiper-slide-active {
      .case-details-carousel-content {
        animation: fadeInRight 0.5s linear;
      }

      .case-details-card {
        text-align: center;
        animation: fadeInRight 0.5s linear;
      }
    }
  }
}
.case-details-carousel-content {
  position: relative;
  // padding: 30px 0px;
  text-align: center;
  overflow: hidden;
  border-radius: 15px;
  .case-details-cshape {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 15px;
    z-index: 1;
  }
  .case-details-img {
    position: relative;
    z-index: 2;
  }
}

.case-details-carousel {
  .swiper-button-prev,
  .swiper-button-next {
    width: 55px;
    height: 55px;
    color: $white;
    font-size: 20px;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1;
      visibility: visible;
    }
  }
  .swiper-button-prev {
    left: auto;
    right: 95px;
    top: auto;
    bottom: 65px;
    background: $btn-warning;
    border: solid 2px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #fc7318 0%, #feb423 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #f7faff inset;
    color: $warning;
    &:hover {
      box-shadow: none;
      color: $white;
      border-color: transparent;
    }
  }
  .swiper-button-next {
    right: 30px;
    top: auto;
    bottom: 65px;
    background: $btn-warning;
    border: solid 2px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #fc7318 0%, #feb423 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #f7faff inset;
    color: $warning;
    &:hover {
      box-shadow: none;
      color: $white;
      border-color: transparent;
    }
  }

  .swiper-button-prev::after,
  .swiper-container-rtl .swiper-button-next::after {
    display: none;
  }
  .swiper-button-next::after,
  .swiper-container-rtl .swiper-button-next::after {
    display: none;
  }
}

.case-details-section {
}
.case-details-card {
  position: relative;
  // background-color: #f7faff;
  // padding: 68px 30px;
  @include max-screen(768, 1199) {
    // padding: 61px 30px;
  }

  @include max-screen(767) {
    // padding: 30px 30px;
  }
  overflow: hidden;
  border-radius: 15px;
}

// .case-shape1 {
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   z-index: 1;
// }

.case-img {
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 auto;
}

.case-details2-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

// case-details-row

.case-details-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include max-screen(991) {
    justify-content: start;
  }
  margin-right: -20px;
  margin-bottom: -20px;
}

.case-details-col {
  padding-right: 20px;
  padding-bottom: 20px;
  @include max-screen(501, 991) {
    max-width: 33%;
    flex: 0 33%;
  }
  @include max-screen(500) {
    max-width: 50%;
    flex: 0 50%;
  }
}
.case-clients-card {
  background-color: rgb(247, 250, 255);
  padding: 70px;
  @include max-screen(992, 1199) {
    padding: 50px;
  }
  @include max-screen(991) {
    padding: 30px;
  }
  @include max-screen(767) {
    padding: 15px;
  }
  margin-bottom: 65px;
  @include max-screen(991) {
    margin-bottom: 35px;
  }
  border-radius: 15px;
}
.case-clients-card-inner {
  background-color: $white;
  padding: 60px 50px;
  @include max-screen(992, 1199) {
    padding: 40px;
  }

  @include max-screen(991) {
    padding: 30px;
  }
  @include max-screen(767) {
    padding: 15px;
  }

  border-radius: 15px;
  .title {
    color: $primary;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 20px;
    font-size: 20px;
    @include max-screen(767) {
      font-size: 18px;
    }
  }
  .clients-hr {
    background-image: -moz-linear-gradient(
      0deg,
      rgb(252, 115, 24) 0%,
      rgb(254, 180, 35) 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      rgb(252, 115, 24) 0%,
      rgb(254, 180, 35) 100%
    );
    background-image: -ms-linear-gradient(
      0deg,
      rgb(252, 115, 24) 0%,
      rgb(254, 180, 35) 100%
    );
    position: relative;
    display: block;
    width: 20px;
    height: 4px;
    margin-bottom: 15px;
    border-radius: 5px;
    &::before {
      position: absolute;
      left: 30px;
      top: 0;
      background-color: rgb(236, 241, 250);
      width: 45px;
      height: 100%;
      content: "";
      border-radius: inherit;
    }
  }

  p {
    font-weight: 400;
    line-height: 1.4;
    font-size: 15px;
    color: #596190;
    @include max-screen(767) {
      font-size: 12px;
    }
  }
}

.share {
  display: inline-block;
  color: $primary;
  font-weight: 700;
  line-height: 1;
  margin-right: 10px;
  font-size: 15px;
}
.share-social-links {
  .social-link-item {
    & + .social-link-item {
      margin-left: 10px;
    }
    .social-link {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #f7faff;
      border: 1px solid #ecedf2;
      color: $primary;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      line-height: 40px;
      &:hover {
        i {
          background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

.share-social-links2 {
  .social-link-item {
    .social-link {
      background-color: #ffffff;
      border-color: #ecedf2;
    }
  }
}
