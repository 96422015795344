.header-bottom {
  padding: 12px 0px;
}

.main-menu {
  .btn-warning {
    margin-left: 20px;
    @include max-screen(992, 1200) {
      margin-left: 0px;
    }
    i {
      margin-left: 15px;
    }
  }
}
.main-menu-item {
  position: relative;
  display: inline-block;
  & + .main-menu-item {
    margin-left: 60px;

    @include max-screen(1201, 1500) {
      margin-left: 45px;
    }
    @include max-screen(992, 1200) {
      margin-left: 25px;
    }
  }
}
.main-menu-link {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 1;
  font-size: 16px;
  color: $primary;
  &:hover {
    color: $warning;
  }
}

.is-sticky {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 10px 0px;
  background: $white;
  transition: all 0.3s;
  animation: fadeInDown 0.5s ease-in-out;
  box-shadow: 0px 3px 11.16px 0.84px rgba(0, 0, 0, 0.1);
  .btn-warning {
    padding: 20px 25px;
    @include max-screen(767) {
      padding: 14px 18px;
    }
  }
}
